export const MULTIPLE_WELCOME_LANGUAGES = {
  en: {
    welcome: "Start a message to begin a consultation",
    text:
      "Hello, I am here to assist you with any medical issues you are facing, do not hesitate to talk to me!",
  },
  "zh-TW": {
    welcome: "開始一條消息開始諮詢",
    text: "您好，我將協助您解決任何醫療問題，請隨時與我交談！",
  },
  fr: {
    welcome: "Commencez un message pour commencer une consultation",
    text:
      "Bonjour, je suis là pour vous aider avec tout problème médical que vous rencontrez, n'hésitez pas à me parler!",
  },
  ja: {
    welcome: "相談を開始するにはメッセージを開始します",
    text:
      "こんにちは、私はあなたが直面している医療問題についてお手伝いします、私に話すのをためらわないでください！",
  },
  vi: {
    welcome: "Bắt đầu một tin nhắn để bắt đầu tư vấn",
    text:
      "Xin chào, tôi ở đây để hỗ trợ bạn giải quyết mọi vấn đề y tế mà bạn đang gặp phải, hãy nói cho tôi biết vấn đề của bạn!",
  },
};
export const CONVERT_LANGUAGES = {
  en: "English",
  vi: "Vietnamese",
  "zh-TW": "Traditional Chinese",
  fr: "French",
  ja: "Japanese",
};
export const WEB_SPEED_GOOGLE_LANGUAGES = {
  en: "en-US",
  vi: "vi-VN",
  "zh-TW": "cmn-Hant-TW",
  fr: "fr-FR",
  ja: "ja-JP",
};
