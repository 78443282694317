<template>
  <div>
    <div
      v-if="!connected"
      class="d-flex align-items-center justify-content-center"
    >
      <div class="lds-ripple">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <!-- <img
     v-if="!connected"
      class="rounded-circle mb-5 w-100"
      src="https://www.svgrepo.com/show/382101/male-avatar-boy-face-man-user.svg"
      alt="drAvatar Avatar"
    /> -->
    <video id="mediaElement" ref="mediaElement" playsinline autoplay></video>
  </div>
</template>

<script>
export default {
  props: {
    taskInput: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      avatarID: "Tyler-insuit-20220721",
      sessionInfo: {},
      peerConnection: null,
      connected: false,
      sessionChecker: null,
      sessionKeepAlive: null,
      timeoutSpeaking: null,
    };
  },
  mounted() {
    this.createNewSession();
    window.addEventListener("beforeunload", this.handleBeforeUnload);
    this.clearAndSetSessionKeepAlive();
    this.sessionChecker = setInterval(async () => {
      if (!this.sessionInfo?.session_id) {
        return;
      }
      await this.listSession();
    }, 5000);
  },
  methods: {
    async handleBeforeUnload(event) {
      // Custom logic to handle when the user refreshes or leaves the page
      console.log("Page is about to be refreshed or closed", event);
      await this.closeConnectionHandler();
    },
    async createNewSession() {
      const avatar = this.avatarID || "Tyler-insuit-20220721";
      const sessionInfo = await this.newSession("low", avatar, "");
      if (!sessionInfo) {
        return;
      }
      const { sdp: serverSdp, ice_servers2: iceServers } = sessionInfo;
      this.sessionInfo = sessionInfo;
      this.peerConnection = new RTCPeerConnection({ iceServers });
      this.peerConnection.ontrack = (event) => {
        if (event.track.kind === "audio" || event.track.kind === "video") {
          this.connected = true;
          console.log("event?.streams?.[0] --->: ", event?.streams);
          this.$nextTick(() => {
            this.$refs.mediaElement.srcObject = event?.streams?.[0];
          });
        }
      };

      const remoteDescription = new RTCSessionDescription(serverSdp);
      await this.peerConnection.setRemoteDescription(remoteDescription);

      await this.startAndDisplaySession();
    },

    async startAndDisplaySession() {
      if (!this.sessionInfo) {
        return;
      }

      const localDescription = await this.peerConnection.createAnswer();
      await this.peerConnection.setLocalDescription(localDescription);

      this.peerConnection.onicecandidate = ({ candidate }) => {
        if (candidate) {
          this.handleICE(this.sessionInfo.session_id, candidate.toJSON());
        }
      };

      await this.startSession(this.sessionInfo.session_id, localDescription);
    },
    clearAndSetSessionKeepAlive() {
      clearInterval(this.sessionKeepAlive);
      this.sessionKeepAlive = setInterval(() => {
        if (this.sessionInfo?.session_id) {
          this.repeat(this.sessionInfo?.session_id, "-");
        }
      }, 60000 * 2);
    },
    async repeatHandler() {
      if (!this.sessionInfo) {
        return;
      }

      const text = this.taskInput.trim();
      if (text === "") {
        return;
      }
      this.clearAndSetSessionKeepAlive();
      await this.repeat(this.sessionInfo.session_id, text);
    },

    async closeConnectionHandler() {
      if (!this.sessionInfo) {
        return;
      }

      this.peerConnection?.close();
      await this.stopSession(this.sessionInfo.session_id);
    },
    async newSession(quality, avatar_id, voice_id) {
      try {
        const response = await this.$store.dispatch("heygen/newSession", {
          quality,
          avatar_id,
          voice_id,
        });
        return response;
      } catch (error) {
        this.$store
          .dispatch(
            "auth/dispatchDialogMessage",
            `Initial New Session: ${error?.message}, Please LOGIN or contact developer`
          )
          .then(() => {
            this.logout();
          });
        return null;
      }
    },
    async createTokenSession() {
      try {
        const response = await this.$store.dispatch(
          "heygen/createTokenSession"
        );
        return response;
      } catch (error) {
        return null;
      }
    },
    logout() {
      this.$store.commit("heygen/resetState");
      this.$store.dispatch("auth/logout");
    },
    async listSession() {
      try {
        const response = await this.$store.dispatch("heygen/listSession");
        const sessions = response?.sessions;

        const sessionInfo = sessions?.find(
          (session) => session.session_id === this.sessionInfo.session_id
        );
        if (!sessionInfo) {
          clearInterval(this.sessionChecker);
          throw new Error("Avatar session has expired");
        }
      } catch (error) {
        this.$store
          .dispatch(
            "auth/dispatchDialogMessage",
            `${error?.message}. Please log in again.`
          )
          .then(() => {
            clearInterval(this.sessionKeepAlive);
            this.logout();
          });
      }
    },
    async interruptTask() {
      const response = await this.$store.dispatch("heygen/interruptTask", {
        session_id: this.sessionInfo.session_id,
      });
      return response;
    },
    async handleICE(session_id, candidate) {
      const response = await this.$store.dispatch("heygen/handleICE", {
        session_id,
        candidate,
      });
      return response;
    },
    async startSession(session_id, sdp) {
      const response = await this.$store.dispatch("heygen/startSession", {
        session_id,
        sdp,
      });
      return response;
    },
    async repeat(session_id, text) {
      try {
        const response = await this.$store.dispatch("heygen/repeat", {
          session_id,
          text,
        });
        this.$store.commit("heygen/setIsProcessing", false);
        // comit isSpeaking to true for the duration of the response
        this.$store.commit("heygen/setIsSpeaking", true);
        this.timeoutSpeaking = setTimeout(() => {
          this.$store.commit("heygen/setIsSpeaking", false);
        }, response.duration_ms + 1500);
        return response;
      } catch (error) {
        this.$store.dispatch(
          "auth/dispatchDialogMessage",
          `Repeat: ${error?.message}, please LOGIN again`
        );
        this.logout();
      }
    },

    async stopSession(session_id) {
      const response = await this.$store.dispatch("heygen/stopSession", {
        session_id,
      });
      return response;
    },
  },
  watch: {
    taskInput() {
      console.log("taskInput changed");
      this.interruptTask();
      this.repeatHandler();
    },
   "$store.state.heygen.isListening": function (newVal) {
      if (!newVal) {
        clearTimeout(this.timeoutSpeaking);
      }
    },
  },
  async beforeUnmount() {
    this.peerConnection?.close();
    clearInterval(this.sessionChecker);
    clearInterval(this.sessionKeepAlive);
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
    await this.stopSession(this.sessionInfo.session_id);
  },
};
</script>

<style scoped>
video {
  width: 100%;
  height: 100%;
  border-radius: 50%; /* Circular border */
}

.lds-ripple,
.lds-ripple div {
  box-sizing: border-box;
}
.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid currentColor;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 8px;
    height: 8px;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 8px;
    height: 8px;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 8px;
    height: 8px;
    opacity: 1;
  }
  100% {
    top: 0;
    left: 0;
    width: 80px;
    height: 80px;
    opacity: 0;
  }
}
</style>
